import React from 'react';

import Landing from '../components/EvidenceBasedScheduling/Landing';

function EbsPage() {
  return (
    <Landing />
  );
}

export default EbsPage;
